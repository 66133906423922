<template>
  <div
    class="w-full bg-white p-4 boxShadow-main"
    :style="{ borderRadius: '10px' }"
  >
    <a-row :gutter="[16]">
      <a-col :lg="18">
        <a-row :gutter="[16, 16]">
          <a-col :lg="12" :md="24" :xs="24" class="gutter-row">
            <TextInputUCVue
              :label="'ค้นหา'"
              :placeholder="'ระบุสิ่งที่ต้องการค้นหา'"
              :textValues="searchValues.global"
              :onChanged="(v) => (this.searchValues.global = v)"
            />
            <p :style="{ opacity: 0.5 }">Keyword</p>
          </a-col>
          <a-col :lg="12" :md="24" :xs="24" class="gutter-row">
            <RangePickkerUCVue
              :onChanged="changedDate"
              :startedDate="searchValues.project_start_date"
              :endedDate="searchValues.project_end_date"
            />
          </a-col>
          <a-col :lg="6" :md="12" :xs="12" class="gutter-row">
            <TreeSelectUC
              label="ประเภทโครงการ"
              placeholder="ระบุประเภทโครงการวิจัย"
            />
          </a-col>
          <a-col :lg="6" :md="12" :xs="12" class="gutter-row">
            <AutoCompleteUC
              label="ชื่อโครงการวิจัย"
              placeholder="ระบุชื่อโครงการวิจัย"
              :options="researchNameOptions"
              :onChanged="(v) => (searchValues.project_id = v)"
              :optionValue="searchValues.project_id"
            />
          </a-col>
          <a-col :lg="6" :md="12" :xs="12" class="gutter-row">
            <AutoCompleteUC
              v-if="$store.state.user.role == 'supporter'"
              label="หัวหน้าโครงการ"
              placeholder="ระบุชื่อหัวหน้าโครงการวิจัย"
              :options="researchLeadOptions"
              :onChanged="(v) => (searchValues.research_name = v)"
              :optionValue="searchValues.research_name"
            />
          </a-col>
        </a-row>
      </a-col>

      <a-col :lg="6" :md="0" :xs="0" class="gutter-row">
        <a-row :gutter="[16, 16]" class="h-full">
          <a-col :md="12">
            <ButtonUCVue
              :header="'test'"
              :label="'ค้นหา'"
              :isBg="true"
              :onClicked="() => onClickedSearch()"
              :iconName="'searchIcon'"
              :iconColor="'#FFF'"
            />
          </a-col>
          <a-col :md="12">
            <ButtonUCVue
              :header="'header'"
              :label="'ล้างค่า'"
              :onClicked="() => handleClearSearch()"
              :iconName="'refreshIcon'"
              :iconColor="'#ED4081'"
            />
          </a-col>
        </a-row>
      </a-col>
      <a-col :lg="0" :md="24" :xs="24" class="gutter-row">
        <a-row :gutter="[16, 16]" class="h-full">
          <a-col :md="12">
            <ButtonUCVue
              :label="'ค้นหา'"
              :isBg="true"
              :onClicked="() => onClickedSearch()"
              :iconName="'searchIcon'"
              :iconColor="'#FFF'"
            />
          </a-col>
          <a-col :md="12">
            <ButtonUCVue
              :label="'ล้างค่า'"
              :onClicked="() => handleClearSearch()"
              :iconName="'refreshIcon'"
              :iconColor="'#ED4081'"
            />
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import ButtonUCVue from "../ButtonUC.vue";
import TextInputUCVue from "../TextInputUC.vue";
import TreeSelectUC from "../TreeSelectUC.vue";
import AutoCompleteUC from "../AutoCompleteUC.vue";
import RangePickkerUCVue from "../RangePickkerUC.vue";
import dayjs from "dayjs";
import DateFunction from "../../services/DateFunction";

export default {
  components: {
    TextInputUCVue,
    ButtonUCVue,
    TreeSelectUC,
    AutoCompleteUC,
    RangePickkerUCVue,
  },

  props: {
    onChanged: {
      Type: Function,
    },
    onClickedSearch: {
      Type: Function,
    },
    researchNameOptions: {
      Type: Array,
      default: () => [],
    },
    researchLeadOptions: {
      Type: Array,
      default: () => [],
    },
  },

  watch: {
    searchValues(newValue) {
      this.onChanged(newValue);
    },
  },

  mounted() {
    this.onChanged(this.searchValues);
  },

  methods: {
    handleSearch() {
      // console.log(this.searchValues);
    },
    changedDate(date) {
      this.searchValues = {
        ...this.searchValues,
        project_start_date: date.startDate,
        project_end_date: date.endDate,
      };
    },
    handleClearSearch() {
      this.searchValues = {
        global: "",
        project_start_date: dayjs().startOf("year"),
        project_end_date: dayjs().endOf("year"),
        researchType: "",
        project_id: "",
        research_name: "",
      };
      this.onClickedSearch();
    },
  },

  data() {
    return {
      research_name: null,
      searchValues: {
        global: "",
        project_start_date: dayjs()
          .startOf("year")
          // .add(543, "year")
          .subtract(1, "year"),
        project_end_date: dayjs().endOf("year"),
        // .add(543, "year"),
        researchType: "",
        project_id: "",
        research_name: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
