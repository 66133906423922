<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    class="inline"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.332031 7.50016C0.332031 3.81816 3.3167 0.833496 6.9987 0.833496C10.6807 0.833496 13.6654 3.81816 13.6654 7.50016C13.6654 11.1822 10.6807 14.1668 6.9987 14.1668C3.3167 14.1668 0.332031 11.1822 0.332031 7.50016ZM6.9987 4.8335C6.9987 4.65669 6.92846 4.48712 6.80344 4.36209C6.67841 4.23707 6.50884 4.16683 6.33203 4.16683C6.15522 4.16683 5.98565 4.23707 5.86063 4.36209C5.7356 4.48712 5.66536 4.65669 5.66536 4.8335V8.16683C5.66536 8.34364 5.7356 8.51321 5.86063 8.63823C5.98565 8.76326 6.15522 8.8335 6.33203 8.8335H9.66537C9.84218 8.8335 10.0117 8.76326 10.1368 8.63823C10.2618 8.51321 10.332 8.34364 10.332 8.16683C10.332 7.99002 10.2618 7.82045 10.1368 7.69543C10.0117 7.5704 9.84218 7.50016 9.66537 7.50016H6.9987V4.8335Z"
      fill="white"
    />
  </svg>
</template>
