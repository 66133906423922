<template>
  <div>
    <a-breadcrumb>
      <!-- <a-breadcrumb-item v-for="(item, idx) in router" :key="idx">{{
        item
      }}</a-breadcrumb-item> -->
      <a-breadcrumb-item>
        {{ $store.state.routeName.name }}
      </a-breadcrumb-item>
      <a-breadcrumb-item v-if="$store.state.childName.name">{{
        $store.state.childName.name
      }}</a-breadcrumb-item>
    </a-breadcrumb>

    <h1 :style="{ fontSize: '32px', marginBottom: '16px' }" class="font-bold">
      {{
        $store.state.childName.name
          ? $store.state.childName.name
          : $store.state.routeName.name
      }}
    </h1>
  </div>
</template>

<script>
// import routes from "../routers";
export default {
  components: {
    // routes,
  },

  mounted() {
    // this.path_name = this.$router.currentRoute._value.path;
    // this.route_name = routes[1].children;
    // console.log(this.path_name, this.route_name);
    // this.checkPath();
  },

  methods: {
    // checkPath() {
    //   const check = this.route_name.find(
    //     (item) => item.path === this.path_name
    //   );
    //   if (check) {
    //     return true;
    //   }
    // },
  },

  data() {
    return {
      router: "",
      route_name: "",
      path_name: "",
    };
  },
};
</script>

<style lang="scss" scoped></style>
