<template>
  <component :is="iconComponent" role="img" :color="color" />
</template>

<script>
import LockIconVue from "./Icons/LockIcon.vue";
import ProgressIconVue from "./Icons/ProgressIcon.vue";
import EditIconVue from "./Icons/EditIcon.vue";
import CompleteIconVue from "./Icons/CompleteIcon.vue";
import OhcIconVue from "./Icons/OhcIcon.vue";
import FundingIconVue from "./Icons/FundingIcon.vue";
import PendingIconVue from "./Icons/PendingIcon.vue";
import ApproveIconVue from "./Icons/ApproveIcon.vue";
import RejectIconVue from "./Icons/RejectIcon.vue";
import WaitingIconVue from "./Icons/WaitingIcon.vue";
import DownloadIconVue from "./Icons/DownloadIcon.vue";
import DeleteIconVue from "./Icons/DeleteIcon.vue";
import ApproveBadgeIconVue from "./Icons/ApproveBadgeIcon.vue";
import cancelButtonIconVue from "./Icons/cancelButtonIcon.vue";
import PencilIconVue from "./Icons/PencilIcon.vue";
import UploadIconVue from "./Icons/UploadIcon.vue";
import SearchIconVue from "./Icons/SearchIcon.vue";
import RefreshIconVue from "./Icons/RefreshIcon.vue";

const icons = {
  lockIcon: LockIconVue,
  progressIcon: ProgressIconVue,
  editIcon: EditIconVue,
  completeIcon: CompleteIconVue,
  ohcIcon: OhcIconVue,
  fundingIcon: FundingIconVue,
  waitingIcon: WaitingIconVue,
  pendingIcon: PendingIconVue,
  approveIcon: ApproveIconVue,
  rejectIcon: RejectIconVue,
  downloadIcon: DownloadIconVue,
  deleteIcon: DeleteIconVue,
  approveBadgeIcon: ApproveBadgeIconVue,
  cancelButtonIcon: cancelButtonIconVue,
  pencilIcon: PencilIconVue,
  uploadIcon: UploadIconVue,
  searchIcon: SearchIconVue,
  refreshIcon: RefreshIconVue,
};

export default {
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value);
      },
    },

    color: {
      type: String,
      default: "",
    },
  },

  computed: {
    iconComponent() {
      return icons[this.name];
    },
  },
};
</script>
