<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M14.7792 2.20424C14.1919 2.0785 13.6712 2.55221 13.6712 3.15285C13.6712 3.66584 14.0563 4.09141 14.5554 4.21C18.4705 5.14028 21.1342 8.88225 20.6312 12.9713C20.2089 16.3131 17.7637 19.0227 14.5492 19.777C14.0541 19.8931 13.6712 20.3144 13.6712 20.8228C13.6712 21.4163 14.1858 21.8842 14.7664 21.7612C19.6918 20.7178 23.134 16.129 22.6212 11.0013C22.2069 6.62864 18.9793 3.10344 14.7792 2.20424ZM11.6712 3.17905C11.6712 2.58006 11.153 2.10723 10.5669 2.23076C9.38479 2.47989 8.25563 2.94062 7.23898 3.60104C6.73449 3.92876 6.69794 4.63047 7.11596 5.06311C7.48712 5.44724 8.08651 5.47847 8.53977 5.19586C9.23925 4.75973 10.0042 4.43426 10.8059 4.23094C11.2962 4.10659 11.6712 3.68488 11.6712 3.17905ZM5.72271 6.46277C5.29843 6.03849 4.59651 6.07034 4.2697 6.57354C3.61047 7.5886 3.1432 8.7163 2.89129 9.90016C2.76696 10.4845 3.23853 11.0013 3.83591 11.0013C4.34276 11.0013 4.76464 10.624 4.88588 10.1318C5.08489 9.32396 5.40704 8.55045 5.84438 7.8411C6.11468 7.40268 6.0869 6.82696 5.72271 6.46277ZM3.84609 13.0013C3.24956 13.0013 2.77969 13.5188 2.90555 14.1019C3.16081 15.2844 3.62721 16.4088 4.28102 17.4229C4.60642 17.9277 5.30965 17.9602 5.73279 17.5341C6.09191 17.1724 6.11955 16.6034 5.85241 16.1693C5.41463 15.458 5.09086 14.6825 4.89296 13.871C4.77303 13.3792 4.35228 13.0013 3.84609 13.0013ZM8.47941 18.8654C8.05795 18.604 7.50545 18.6258 7.14733 18.9689C6.71747 19.3808 6.73994 20.0789 7.23683 20.4068C8.25222 21.0766 9.38157 21.5556 10.569 21.8199C11.1516 21.9496 11.6712 21.4805 11.6712 20.8836C11.6712 20.379 11.2944 19.9595 10.8041 19.8401C9.98263 19.6401 9.19795 19.3111 8.47941 18.8654ZM12.4212 7.00125C12.8354 7.00125 13.1712 7.33704 13.1712 7.75125V11.9725C13.1712 12.1453 13.2623 12.3053 13.4109 12.3935L17.0458 14.5502C17.39 14.7544 17.5009 15.2005 17.2926 15.5422C17.087 15.8793 16.648 15.9873 16.3095 15.7842L11.9469 13.1667C11.7758 13.064 11.6712 12.8792 11.6712 12.6797V7.75125C11.6712 7.33704 12.007 7.00125 12.4212 7.00125Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "",
    },
  },
};
</script>
