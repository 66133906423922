<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      d="M8.5 1.5C10.3565 1.5 12.137 2.2375 13.4497 3.55025C14.7625 4.86301 15.5 6.64348 15.5 8.5C15.5 10.3565 14.7625 12.137 13.4497 13.4497C12.137 14.7625 10.3565 15.5 8.5 15.5C6.64348 15.5 4.86301 14.7625 3.55025 13.4497C2.2375 12.137 1.5 10.3565 1.5 8.5C1.5 6.64348 2.2375 4.86301 3.55025 3.55025C4.86301 2.2375 6.64348 1.5 8.5 1.5ZM7.628 9.881L6.073 8.325C6.01725 8.26925 5.95107 8.22503 5.87824 8.19486C5.8054 8.16469 5.72734 8.14917 5.6485 8.14917C5.56966 8.14917 5.4916 8.16469 5.41876 8.19486C5.34593 8.22503 5.27975 8.26925 5.224 8.325C5.11142 8.43758 5.04817 8.59028 5.04817 8.7495C5.04817 8.90872 5.11142 9.06142 5.224 9.174L7.204 11.154C7.25959 11.21 7.32572 11.2545 7.39858 11.2848C7.47143 11.3152 7.54958 11.3308 7.6285 11.3308C7.70742 11.3308 7.78557 11.3152 7.85842 11.2848C7.93128 11.2545 7.99741 11.21 8.053 11.154L12.153 7.053C12.2095 6.99748 12.2544 6.93133 12.2852 6.85836C12.316 6.78539 12.3321 6.70705 12.3324 6.62785C12.3328 6.54864 12.3175 6.47015 12.2874 6.3969C12.2573 6.32365 12.2129 6.25708 12.157 6.20104C12.101 6.145 12.0345 6.1006 11.9613 6.0704C11.888 6.04019 11.8096 6.02478 11.7304 6.02506C11.6512 6.02533 11.5728 6.04129 11.4998 6.072C11.4268 6.10271 11.3606 6.14757 11.305 6.204L7.628 9.881Z"
      fill="white"
    />
  </svg>
</template>
