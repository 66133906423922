import axios from "axios";
import { HTTPHeader } from "./helpers";
import router from "../src/routers";
export const HTTP = axios.create({
  // baseURL: "http://127.0.0.1:8000/api",
  // baseURL: "https://econ-research.undefined.co.th/public/api",
  // baseURL: "https://undefined-research.undefined.co.th/public/api",
  baseURL: "https://ert.econ.cmu.ac.th/public/api",
  headers: HTTPHeader(),
  mode: "cors",
});

HTTP.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("user");
      router.replace({ name: "login" });
    }
    return Promise.reject(error);
  }
);

HTTP.interceptors.request.use((request) => {
  request.headers = HTTPHeader();
  return request;
});
