<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      d="M4.30156 13.3C4.30094 12.5047 4.52617 11.7255 4.95104 11.0531C5.37591 10.3808 5.98296 9.84286 6.70156 9.502V7.498C5.98296 7.15714 5.37591 6.61924 4.95104 5.94688C4.52617 5.27452 4.30094 4.49535 4.30156 3.7H3.70156C3.37019 3.7 3.10156 3.43137 3.10156 3.1C3.10156 2.76863 3.37019 2.5 3.70156 2.5H13.3016C13.6329 2.5 13.9016 2.76863 13.9016 3.1C13.9016 3.43137 13.6329 3.7 13.3016 3.7H12.7016C12.7022 4.49535 12.477 5.27452 12.0521 5.94688C11.6272 6.61924 11.0202 7.15714 10.3016 7.498V9.502C11.0202 9.84286 11.6272 10.3808 12.0521 11.0531C12.477 11.7255 12.7022 12.5047 12.7016 13.3H13.3016C13.6329 13.3 13.9016 13.5686 13.9016 13.9C13.9016 14.2314 13.6329 14.5 13.3016 14.5H3.70156C3.37019 14.5 3.10156 14.2314 3.10156 13.9C3.10156 13.5686 3.37019 13.3 3.70156 13.3H4.30156ZM5.50156 3.7C5.50142 4.39159 5.74023 5.06199 6.17758 5.59774C6.61493 6.13349 7.22394 6.50168 7.90156 6.64V8.5H9.10156V6.64C9.77918 6.50168 10.3882 6.13349 10.8255 5.59774C11.2629 5.06199 11.5017 4.39159 11.5016 3.7H5.50156Z"
      fill="white"
    />
  </svg>
</template>
