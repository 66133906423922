<template>
  <div class="flex flex-wrap">
    <div class="w-full mb-12 flex flex-col gap-4">
      <SearchBoxVue
        :onChanged="(v) => (search_value = v)"
        :onClickedSearch="getResearchApprove"
        :researchNameOptions="project_name"
        :researchLeadOptions="research_name"
      />
      <TableUCVue
        :columnsList="columns"
        :tableData="researchData"
        :onClickedRows="onClickRows"
        :paginationConfig="paginationConfig"
        :filterTable="filterTable"
        :sortBy="sort_by"
        :sortChanged="
          (v) => {
            sort_by = v;
            getResearchApprove();
          }
        "
        :approveClicked="onClickApprove"
        :tableStrip="true"
      />
    </div>
  </div>
</template>
<script>
import SearchBoxVue from "../../components/SearchBox/SearchBox.vue";
import TableUCVue from "../../components/TableUC.vue";
import DateFunction from "../../services/DateFunction";

import { HTTP } from "../../http-common";

export default {
  components: {
    SearchBoxVue,
    TableUCVue,
  },

  mounted() {
    this.getResearchApprove();
    this.$store.commit("setBread", this.$router.currentRoute._value);
  },

  methods: {
    async getResearchApprove() {
      let loader = this.$loading.show();
      let body = {
        limit: this.paginationConfig.pageSize,
        offset:
          this.paginationConfig.pageSize * (this.paginationConfig.current - 1),
        search_value: {
          ...this.search_value,
          project_start_date: DateFunction.getSearchDate(
            this.search_value.project_start_date
          ),
          project_end_date: DateFunction.getSearchDate(
            this.search_value.project_end_date
          ),
        },
        sort_column: "",
        sort_by: "",
      };

      switch (this.sort_by) {
        case 0:
          body = { ...body, sort_column: "updated_date", sort_by: "desc" };
          break;
        case 1:
          body = { ...body, sort_column: "updated_date", sort_by: "asc" };
          break;
        case 2:
          body = { ...body, sort_column: "created_date", sort_by: "desc" };
          break;
        case 3:
          body = { ...body, sort_column: "created_date", sort_by: "asc" };
          break;
      }

      const { data } = await HTTP.post("/getProjectApproves", body);

      this.project_name = data.project_name;

      this.research_name = data.research_name;

      if (data.success && data?.data && data?.data?.length > 0) {
        this.paginationConfig = { ...this.paginationConfig, total: data.total };
        this.researchData = data.data;
        loader.hide();
      } else {
        this.paginationConfig.total = 0;
        this.researchData = [];
        loader.hide();
      }
    },

    onClickApprove(research, type) {
      this.$swal
        .fire({
          title: type == "approve" ? "ยืนยันการอนุมัติ" : "ยืนยันการไม่อนุมัติ",
          icon: type == "approve" ? "success" : "error",
          showCancelButton: true,
          confirmButtonColor: type == "approve" ? "#84CF75" : "#ED4040",
          cancelButtonColor: type == "approve" ? "#F1FAEF" : "#FDECEC",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          customClass: {
            cancelButton:
              type == "approve"
                ? "cancelButtonOnApprove"
                : "cancelButtonOnReject",
            popup: type == "approve" ? "borderApprove" : "borderReject",
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.approveProject(research, type);
          }
        });
    },

    async approveProject(research, type) {
      let loader = this.$loading.show();
      const { data } = await HTTP.post("/approveProject", {
        project_id: research.project_id,
        approve_status: type,
        approve_type: research.approve_type,
        approve_index: research.approve_index,
      });

      if (data.success) {
        loader.hide();
        this.$swal.fire({
          title: "ทำรายการสำเร็จ",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
          width: 300,
        });

        setTimeout(() => {
          this.getResearchApprove();
        }, 2000);
      }
    },

    clickedTest() {},

    handlePageChange(page) {
      this.paginationConfig = {
        ...this.paginationConfig,
        current: page,
      };
      this.fetchResearchList();
    },

    handlePageSizeChange(current, pageSize) {
      this.paginationConfig = {
        ...this.paginationConfig,
        current: current,
        pageSize: pageSize,
      };
    },

    onClickRows(data) {
      this.$router.push(`/research-details/${data.project_id}`);
    },
  },

  data() {
    return {
      loader: "",
      search_value: null,
      columns: [
        {
          title: "ชื่อโครงการวิจัย",
          dataIndex: "project_name_tha",
          width: 250,
        },
        {
          title: "ประเภทโครงการวิจัย",
          dataIndex: "research_type",
          width: 150,
        },
        {
          title: "หัวหน้าโครงการ",
          dataIndex: "research_name",
          width: 280,
        },
        {
          title: "วันที่เริ่มต้นโครงการ",
          dataIndex: "project_start_date",
          align: "center",
          width: 100,
        },
        {
          title: "วันที่สิ้นสุดโครงการ",
          dataIndex: "project_finish_date",
          align: "center",
          width: 100,
        },
        {
          title: "ขั้นตอนการดำเนินงาน",
          dataIndex: "approve_type",
          width: 200,
        },
        {
          title: "ผลการดำเนินงาน",
          dataIndex: "approve_status",
          align: "center",
          width: 150,
        },
        {
          title: "วันที่ดำเนินการ",
          dataIndex: "updated_date",
          align: "center",
          width: 150,
        },
        {
          title: "",
          dataIndex: "approve_operation",
          align: "center",
          width: 50,
        },
      ],

      sort_by: 0,

      researchData: [],

      filterTable: [
        { label: "เรียงตามโครงการวิจัยที่อัปเดตใหม่ที่สุด", value: 0 },
        { label: "เรียงตามโครงการวิจัยที่อัปเดตเก่าที่สุด", value: 1 },
        { label: "เรียงตามโครงการวิจัยที่สร้างใหม่ที่สุด", value: 2 },
        { label: "เรียงตามโครงการวิจัยที่สร้างเก่าที่สุด", value: 3 },
      ],

      paginationConfig: {
        total: 0,
        pageSize: 10,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: [5, 10, 50, 100],
        onChange: this.handlePageChange,
        onShowSizeChange: this.handlePageSizeChange,
      },

      project_name: [],
      research_name: [],
    };
  },
};
</script>

<style scoped>
.swal2-icon-success {
  border: 2px solid #84cf75 !important;
}
</style>
