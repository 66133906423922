<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M16.8721 3C17.6373 2.99996 18.3736 3.29233 18.9303 3.81728C19.4871 4.34224 19.8222 5.06011 19.8671 5.824L19.8721 6V16H20.6221C21.2691 16 21.8021 16.492 21.8661 17.122L21.8721 17.25V19C21.8721 19.7652 21.5797 20.5015 21.0548 21.0583C20.5298 21.615 19.812 21.9501 19.0481 21.995L18.8721 22H8.87207C8.10686 22 7.37056 21.7077 6.81381 21.1827C6.25706 20.6578 5.92196 19.9399 5.87707 19.176L5.87207 19V9H4.12207C3.81261 9.00014 3.5141 8.88549 3.2843 8.67823C3.05451 8.47097 2.90976 8.18583 2.87807 7.878L2.87207 7.75V6C2.87203 5.23479 3.1644 4.49849 3.68935 3.94174C4.21431 3.38499 4.93218 3.04989 5.69607 3.005L5.87207 3H16.8721ZM19.8721 18H10.8721V19C10.8721 19.35 10.8121 19.687 10.7021 20H18.8721C19.1373 20 19.3916 19.8946 19.5792 19.7071C19.7667 19.5196 19.8721 19.2652 19.8721 19V18ZM12.8721 12H10.8721C10.6172 12.0003 10.372 12.0979 10.1867 12.2728C10.0014 12.4478 9.88983 12.687 9.8749 12.9414C9.85996 13.1958 9.94274 13.4464 10.1063 13.6418C10.2699 13.8373 10.502 13.9629 10.7551 13.993L10.8721 14H12.8721C13.127 13.9997 13.3721 13.9021 13.5574 13.7272C13.7428 13.5522 13.8543 13.313 13.8692 13.0586C13.8842 12.8042 13.8014 12.5536 13.6378 12.3582C13.4742 12.1627 13.2422 12.0371 12.9891 12.007L12.8721 12ZM14.8721 8H10.8721C10.6069 8 10.3525 8.10536 10.165 8.29289C9.97743 8.48043 9.87207 8.73478 9.87207 9C9.87207 9.26522 9.97743 9.51957 10.165 9.70711C10.3525 9.89464 10.6069 10 10.8721 10H14.8721C15.1373 10 15.3916 9.89464 15.5792 9.70711C15.7667 9.51957 15.8721 9.26522 15.8721 9C15.8721 8.73478 15.7667 8.48043 15.5792 8.29289C15.3916 8.10536 15.1373 8 14.8721 8ZM5.87207 5C5.60685 5 5.3525 5.10536 5.16496 5.29289C4.97743 5.48043 4.87207 5.73478 4.87207 6V7H5.87207V5Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "",
    },
  },
};
</script>
