<template>
  <a-layout>
    <sidebar />
    <div
      class="relative"
      :style="{ background: '#f5f5f5' }"
      :class="{
        'md:ml-64': $store.state.sideBar,
        'transition-all duration-200 ease-in-out': true,
      }"
    >
      <admin-navbar />
      <div class="px-4 md:px-6 mx-auto w-ful pb-6 pt-24 min-h-screen h-full">
        <Breadcrumb />
        <router-view />
      </div>
    </div>
  </a-layout>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";

export default {
  name: "admin-layout",
  components: {
    Breadcrumb,
    AdminNavbar,
    Sidebar,
  },

  data() {
    return {};
  },
};
</script>

<style scoped></style>
