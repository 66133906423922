<template>
  <Transition name="sidebarSlide">
    <nav
      v-if="$store.state.sideBar"
      class="left-0 block fixed top-0 bottom-0 md:overflow-y-auto flex-row flex-nowrap md:overflow-hidden shadow-xl bg-white md:w-64 w-56 z-10 py-4 px-4"
    >
      <div
        class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
      >
        <div class="flex justify-center">
          <img
            src="../../assets/EconLogo2.png"
            alt="logo"
            :style="{ height: '40px' }"
          />
        </div>
        <div
          class="flex flex-col items-stretch opacity-100 md:relative md:mt-8 mt-20 shadow-none absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-full flex-1 rounded md:p-0 p-2"
        >
          <ul class="flex-col min-w-full flex list-none">
            <li class="items-center">
              <router-link
                to="/research-list"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  :href="href"
                  @click="navigate"
                  class="uppercase px-3 py-4 font-bold flex items-center"
                  :style="[
                    isActive || $store.state.routeName.href == href
                      ? researchListLinkStyle
                      : defaultLinkStyle,
                  ]"
                >
                  <i class="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.67 2.44465C2.58625 2.34686 2.53229 2.22712 2.51452 2.09961C2.49674 1.9721 2.5159 1.84216 2.56971 1.7252C2.62353 1.60824 2.70974 1.50916 2.81814 1.43971C2.92655 1.37025 3.05259 1.33333 3.18133 1.33331H11.5C11.894 1.33331 12.2841 1.41091 12.6481 1.56167C13.012 1.71244 13.3427 1.93342 13.6213 2.21199C13.8999 2.49057 14.1209 2.82129 14.2716 3.18526C14.4224 3.54924 14.5 3.93935 14.5 4.33331C14.5 4.72728 14.4224 5.11739 14.2716 5.48136C14.1209 5.84534 13.8999 6.17606 13.6213 6.45463C13.3427 6.73321 13.012 6.95419 12.6481 7.10495C12.2841 7.25572 11.894 7.33331 11.5 7.33331H3.18133C3.05259 7.3333 2.92655 7.29638 2.81814 7.22692C2.70974 7.15746 2.62353 7.05838 2.56971 6.94142C2.5159 6.82447 2.49674 6.69453 2.51452 6.56702C2.53229 6.43951 2.58625 6.31976 2.67 6.22198L3.17267 5.63531C3.48341 5.27282 3.65423 4.81111 3.65423 4.33365C3.65423 3.85619 3.48341 3.39448 3.17267 3.03198L2.67 2.44465ZM2.5 11C2.5 10.2043 2.81607 9.44127 3.37868 8.87866C3.94129 8.31605 4.70435 7.99998 5.5 7.99998H13.8187C13.9474 7.99999 14.0735 8.03692 14.1819 8.10637C14.2903 8.17583 14.3765 8.27491 14.4303 8.39187C14.4841 8.50883 14.5033 8.63876 14.4855 8.76627C14.4677 8.89379 14.4137 9.01353 14.33 9.11131L13.8267 9.69798C13.5159 10.0605 13.3451 10.5222 13.3451 10.9996C13.3451 11.4771 13.5159 11.9388 13.8267 12.3013L14.33 12.888C14.414 12.9857 14.4681 13.1056 14.486 13.2332C14.5039 13.3608 14.4848 13.4909 14.4309 13.608C14.3771 13.725 14.2908 13.8242 14.1823 13.8937C14.0737 13.9632 13.9475 14.0001 13.8187 14H5.5C4.70435 14 3.94129 13.6839 3.37868 13.1213C2.81607 12.5587 2.5 11.7956 2.5 11Z"
                        :fill="[
                          isActive || $store.state.routeName.href == href
                            ? '#FFFFFF'
                            : '#333436',
                        ]"
                      />
                    </svg>
                  </i>
                  รายการโครงการวิจัย
                </a>
              </router-link>
            </li>

            <li
              class="items-center"
              v-if="$store.state.user.role == 'supporter'"
            >
              <router-link
                to="/research-check"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  :href="href"
                  @click="navigate"
                  class="uppercase px-3 py-4 font-bold flex items-center"
                  :style="[
                    isActive || $store.state.routeName.href == href
                      ? researchListLinkStyle
                      : defaultLinkStyle,
                  ]"
                >
                  <i class="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M3.16667 2C2.79848 2 2.5 2.29848 2.5 2.66667V13.3333C2.5 13.7015 2.79848 14 3.16667 14H13.8333C14.2015 14 14.5 13.7015 14.5 13.3333V7.04763C14.5 6.75066 14.141 6.60194 13.931 6.81193L12.6289 8.11401C12.5664 8.17652 12.5312 8.26131 12.5312 8.34971V11.6979C12.5312 11.882 12.382 12.0312 12.1979 12.0312H4.80208C4.61799 12.0312 4.46875 11.882 4.46875 11.6979V4.30208C4.46875 4.11799 4.61799 3.96875 4.80208 3.96875H8.15029C8.23869 3.96875 8.32348 3.93363 8.38599 3.87112L9.68807 2.56904C9.89806 2.35905 9.74934 2 9.45237 2H3.16667ZM13.0358 2.38733C12.9056 2.25715 12.6945 2.25716 12.5644 2.38735L8.69398 6.25837C8.56381 6.38856 8.35274 6.38857 8.22256 6.25839L7.14276 5.17859C7.01256 5.04839 6.80146 5.04841 6.6713 5.17864L5.5518 6.29865C5.42167 6.42884 5.4217 6.63988 5.55188 6.77003L7.98681 9.2044C8.24716 9.4647 8.66923 9.46468 8.92956 9.20435L10.049 8.08488L14.1552 3.97808C14.2853 3.8479 14.2853 3.63686 14.1552 3.5067L13.0358 2.38733Z"
                        :fill="[
                          isActive || $store.state.routeName.href == href
                            ? '#FFFFFF'
                            : '#333436',
                        ]"
                      />
                    </svg>
                  </i>
                  ตรวจสอบโครงการวิจัย
                </a>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </Transition>
</template>

<script>
export default {
  computed: {
    sideBar() {
      return this.$store.state.sideBar;
    },
    userRole() {
      return this.$store.state.user.role;
    },

    researchListLinkStyle() {
      return {
        backgroundColor: "#F06599",
        color: "#FFFFFF",
        borderRadius: "40px",
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
      };
    },
    defaultLinkStyle() {
      return {
        color: "#333436",
      };
    },
  },
  components: {},
};
</script>
