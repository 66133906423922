<template>
  <div
    class="flex items-start justify-center flex-col gap-1"
    :class="classList"
  >
    <div class="flex">
      <label :for="name" v-if="label">{{ label }}</label>
      <p v-if="isRequire" class="ml-1" :style="{ color: '#ED4040' }">*</p>
    </div>
    <div class="w-full relative">
      <a-input
        :name="name"
        :type="type"
        :id="id"
        :style="{
          borderRadius: '40px',
          border: '1px solid #B8B9BA',
          background: '#FFF',
          padding: prefix ? '7px 48px' : '7px 16px',
          width: '100%',
          outline: error ? '1px solid #ED4040' : '',
        }"
        :maxlength="maxLength"
        :placeholder="placeholder"
        :value="dataValues"
        @change="(e) => handleChange(e)"
      />
      <p v-if="error" :style="{ color: '#ED4040', marginTop: '2px' }">
        {{ error }}
      </p>
      <div class="absolute" v-if="prefix" :style="{ top: '8px', left: '16px' }">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path :d="prefix" fill="#B8B9BA" />
        </svg>
      </div>
      <div
        class="absolute"
        v-if="suffix"
        :style="{ top: '8px', right: '16px' }"
      >
        <button @click="onClicked" class="rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <path :d="suffix" fill="#B8B9BA" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      Type: String,
    },
    name: {
      Type: String,
    },
    id: {
      Type: String,
    },
    placeholder: {
      Type: String,
    },
    model: {
      Type: String,
    },
    type: {
      Type: String,
      default: "text",
    },
    classList: {
      Type: String,
    },
    textValues: {
      Type: String,
    },
    isRequire: {
      Type: Boolean,
      default: false,
    },
    prefix: {
      Type: String,
    },
    suffix: {
      Type: String,
    },
    onClicked: {
      Type: Function,
    },
    error: {
      Type: String,
    },
    onChanged: {
      Type: Function,
    },
    maxLength: {
      Type: Number,
      default: 255,
    },
  },

  watch: {
    textValues(newValue) {
      this.dataValues = newValue;
    },
  },

  // mounted() {
  //   this.dataValues = this.textValues;
  // },

  methods: {
    handleChange(e) {
      this.onChanged(e.target.value);
      this.dataValues = e.target.value;
    },
  },

  data() {
    return {
      dataValues: "",
    };
  },
};
</script>

<style scoped>
button:focus {
  outline: 0;
}
</style>
