<template>
  <div class="flex items-start justify-center flex-col gap-1">
    <div class="flex">
      <label v-if="label">{{ label }}</label>
    </div>
    <a-tree-select
      :tree-data="treeData"
      tree-checkable
      allow-clear
      :show-checked-strategy="SHOW_PARENT"
      :placeholder="placeholder"
      tree-node-filter-prop="label"
      class="w-full"
    ></a-tree-select>
  </div>
</template>

<script>
import { TreeSelect } from "ant-design-vue";

export default {
  props: {
    label: {
      type: String,
    },
    researchTypeList: {
      type: Array,
    },
    placeholder: {
      type: String,
    },
  },

  data() {
    return {
      SHOW_PARENT: TreeSelect.SHOW_PARENT,
      treeData: [
        {
          label: "ทั้งหมด",
          value: "all",
          children: [
            {
              label: "โครงการวิจัย",
              value: "0",
            },
            {
              label: "วิชารับใช้สังคม",
              value: "1",
            },
            {
              label: "งานสร้างสรรค์",
              value: "2",
            },
          ],
        },
      ],
    };
  },
};
</script>
