<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M18.9707 8H17.9707V6C17.9707 3.24 15.7307 1 12.9707 1C10.2107 1 7.9707 3.24 7.9707 6V8H6.9707C5.8707 8 4.9707 8.9 4.9707 10V20C4.9707 21.1 5.8707 22 6.9707 22H18.9707C20.0707 22 20.9707 21.1 20.9707 20V10C20.9707 8.9 20.0707 8 18.9707 8ZM12.9707 17C11.8707 17 10.9707 16.1 10.9707 15C10.9707 13.9 11.8707 13 12.9707 13C14.0707 13 14.9707 13.9 14.9707 15C14.9707 16.1 14.0707 17 12.9707 17ZM9.9707 8V6C9.9707 4.34 11.3107 3 12.9707 3C14.6307 3 15.9707 4.34 15.9707 6V8H9.9707Z"
      fill="#D6D6D8"
    />
  </svg>
</template>