import CryptoJS from "crypto-js";
import { Buffer } from 'buffer/';

const secretKey = "undefined2023";

export default {
  encrypt: (text) => {
    return CryptoJS.AES.encrypt(text, secretKey).toString();
  },

  decrypt: (text) => {
    return CryptoJS.AES.decrypt(text, secretKey).toString(CryptoJS.enc.Utf8);
  },
  EncodeKey:(id) => {
    let buf = Buffer.from(id, "ascii");
    id = buf.toString("base64");
    return id;
  },
  DecodeKey:(id) => {
    let buff = Buffer.from(id, "base64");
    id = buff.toString("ascii");
    return id;
  }
};
