<template>
  <div class="flex flex-col gap-4">
    <div
      class="p-4 rounded-main boxShadow-main flex justify-between items-center"
      :style="{ backgroundColor: '#FFF' }"
      v-if="isHeader"
    >
      <p class="text-xs">
        {{
          `หน้าที่ ${paginationConfig.current} / ${
            paginationConfig.current * paginationConfig.pageSize -
            paginationConfig.pageSize +
            1
          } - ${paginationConfig.current * paginationConfig.pageSize} จาก ${
            paginationConfig.total
          } รายการ`
        }}
        <!-- {{
          `หน้าที่ ${paginationConfig.current} / ${
            paginationConfig.current * paginationConfig.pageSize -
            paginationConfig.pageSize +
            1
          } - ${paginationConfig.current * paginationConfig.pageSize}`
        }} -->
      </p>
      <a-select
        :value="valueSort"
        :style="{ width: '300px' }"
        @change="(v) => changeValues(v)"
      >
        <a-select-option
          :value="item.value"
          v-for="item in filterTable"
          :key="item.value"
          >{{ item.label }}</a-select-option
        >
      </a-select>
    </div>

    <!-- <div
      class="p-4 rounded-main boxShadow-main"
      :style="{ backgroundColor: '#FFF' }"
      v-if="isFilterType"
    >
      <a-row :gutter="16">
        <a-col :span="8">
          <a-button
            class="w-full button-tap"
            :class="activeValue == 'pending' ? 'active' : ''"
            ref="activeTab"
            @click="changeTab('pending')"
          >
            <div class="flex items-center justify-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.83203 12.5C2.83203 6.977 7.30903 2.5 12.832 2.5C18.355 2.5 22.832 6.977 22.832 12.5C22.832 18.023 18.355 22.5 12.832 22.5C7.30903 22.5 2.83203 18.023 2.83203 12.5ZM12.832 8.5C12.832 8.23478 12.7267 7.98043 12.5391 7.79289C12.3516 7.60536 12.0972 7.5 11.832 7.5C11.5668 7.5 11.3125 7.60536 11.1249 7.79289C10.9374 7.98043 10.832 8.23478 10.832 8.5V13.5C10.832 13.7652 10.9374 14.0196 11.1249 14.2071C11.3125 14.3946 11.5668 14.5 11.832 14.5H16.832C17.0972 14.5 17.3516 14.3946 17.5391 14.2071C17.7267 14.0196 17.832 13.7652 17.832 13.5C17.832 13.2348 17.7267 12.9804 17.5391 12.7929C17.3516 12.6054 17.0972 12.5 16.832 12.5H12.832V8.5Z"
                  :fill="activeValue == 'pending' ? '#ed4081' : '#f39dbe'"
                />
              </svg>
              <p>รออนุมัติ</p>
            </div>
          </a-button>
        </a-col>

        <a-col :span="8">
          <a-button
            class="w-full button-tap"
            @click="changeTab('approve')"
            :class="activeValue == 'approve' ? 'active' : ''"
          >
            <div class="flex gap-2 justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M12 2C14.7848 2 17.4555 3.10625 19.4246 5.07538C21.3938 7.04451 22.5 9.71523 22.5 12.5C22.5 15.2848 21.3938 17.9555 19.4246 19.9246C17.4555 21.8938 14.7848 23 12 23C9.21523 23 6.54451 21.8938 4.57538 19.9246C2.60625 17.9555 1.5 15.2848 1.5 12.5C1.5 9.71523 2.60625 7.04451 4.57538 5.07538C6.54451 3.10625 9.21523 2 12 2ZM10.692 14.5715L8.3595 12.2375C8.27588 12.1539 8.17661 12.0876 8.06736 12.0423C7.9581 11.997 7.84101 11.9737 7.72275 11.9737C7.60449 11.9737 7.4874 11.997 7.37814 12.0423C7.26889 12.0876 7.16962 12.1539 7.086 12.2375C6.91712 12.4064 6.82225 12.6354 6.82225 12.8743C6.82225 13.1131 6.91712 13.3421 7.086 13.511L10.056 16.481C10.1394 16.565 10.2386 16.6317 10.3479 16.6773C10.4571 16.7228 10.5744 16.7462 10.6927 16.7462C10.8111 16.7462 10.9284 16.7228 11.0376 16.6773C11.1469 16.6317 11.2461 16.565 11.3295 16.481L17.4795 10.3295C17.5642 10.2462 17.6316 10.147 17.6778 10.0375C17.724 9.92809 17.7481 9.81057 17.7487 9.69177C17.7492 9.57297 17.7262 9.45523 17.6811 9.34535C17.6359 9.23547 17.5694 9.13562 17.4854 9.05156C17.4015 8.96751 17.3017 8.9009 17.1919 8.8556C17.0821 8.81029 16.9644 8.78718 16.8455 8.78759C16.7267 8.788 16.6092 8.81193 16.4997 8.858C16.3902 8.90407 16.2909 8.97136 16.2075 9.056L10.692 14.5715Z"
                  :fill="activeValue == 'approve' ? '#ed4081' : '#f39dbe'"
                />
              </svg>
              <p>อนุมัติ</p>
            </div>
          </a-button>
        </a-col>

        <a-col :span="8">
          <a-button
            class="w-full button-tap"
            @click="changeTab('cancel')"
            :class="activeValue == 'cancel' ? 'active' : ''"
          >
            <div class="flex items-center justify-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M19.2656 5.39961C15.3656 1.49961 8.96563 1.49961 5.06563 5.39961C1.16563 9.29961 1.16563 15.6996 5.06563 19.5996C8.96563 23.4996 15.2656 23.4996 19.1656 19.5996C23.0656 15.6996 23.1656 9.29961 19.2656 5.39961ZM14.9656 16.6996L12.1656 13.8996L9.36562 16.6996L7.96563 15.2996L10.7656 12.4996L7.96563 9.69961L9.36562 8.29961L12.1656 11.0996L14.9656 8.29961L16.3656 9.69961L13.5656 12.4996L16.3656 15.2996L14.9656 16.6996Z"
                  :fill="activeValue == 'cancel' ? '#ed4081' : '#f39dbe'"
                />
              </svg>
              <p>ไม่อนุมัติ</p>
            </div>
          </a-button>
        </a-col>
      </a-row>
    </div> -->

    <!-- table -->
    <div
      class="rounded-main boxShadow-main"
      :style="{ backgroundColor: '#FFF', overflow: 'hidden' }"
    >
      <a-table
        :columns="columnsList"
        :data-source="numberedData"
        :pagination="paginationConfig"
        :locale="{ emptyText: 'ไม่พบข้อมูล' }"
        class="custom-table"
        :customRow="customRow"
        :row-class-name="(_record, index) => getRowClassName(_record)"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'approve_operation'">
            <div
              class="flex gap-2 justify-items-center"
              v-if="
                record.approve_status != 'approve' &&
                record.approve_status != 'cancel'
              "
            >
              <button>
                <BaseIconVue
                  name="approveIcon"
                  @click.stop="() => approveClicked(record, 'approve')"
                  :color="'#76CA66'"
                />
              </button>
              <button>
                <BaseIconVue
                  name="cancelButtonIcon"
                  @click.stop="() => approveClicked(record, 'cancel')"
                  :color="'#ED4040'"
                />
              </button>
            </div>
          </template>

          <template v-else-if="column.dataIndex === 'otherFile-oparetion'">
            <div class="flex gap-2 justify-center">
              <a-popover>
                <template #content> ดาวน์โหลด </template>
                <button>
                  <BaseIconVue
                    name="downloadIcon"
                    @click.stop="() => fileDownload(record)"
                  />
                </button>
              </a-popover>
              <a-popover>
                <template #content> ลบไฟล์ </template>
                <button>
                  <BaseIconVue
                    name="deleteIcon"
                    @click.stop="() => fileDeleted(record)"
                  />
                </button>
              </a-popover>
            </div>
          </template>

          <template v-else-if="column.dataIndex === 'project_name_tha'">
            <a-popover>
              <template #content>
                <p>{{ record.project_name_tha }}</p>
              </template>
              <p class="truncate-text">
                {{ record.project_name_tha }}
              </p>
            </a-popover>
          </template>

          <template v-else-if="column.dataIndex === 'approve_type'">
            <p>
              {{ approveStatus(record.approve_type, record.approve_index) }}
            </p>
          </template>

          <template v-else-if="column.dataIndex === 'approve_status'">
            <div v-if="record.approve_status == 'waiting'" class="flexBox">
              <span class="status-waiting badge">
                <BaseIconVue name="waitingIcon" />
                <p class="inline">รอดำเนินการ</p>
              </span>
            </div>
            <div v-else-if="record.approve_status == 'pending'" class="flexBox">
              <span class="status-pending badge">
                <BaseIconVue name="pendingIcon" />
                รอการอนุมัติ
              </span>
            </div>
            <div v-else-if="record.approve_status == 'approve'" class="flexBox">
              <span class="status-approve badge">
                <BaseIconVue name="approveBadgeIcon" />
                อนุมัติ
              </span>
            </div>
            <div v-else-if="record.approve_status == 'cancel'" class="flexBox">
              <span class="status-reject badge">
                <BaseIconVue name="rejectIcon" />
                ไม่อนุมัติ
              </span>
            </div>
            <div v-else-if="record.approve_status == 'null'" class="flexBox">
              -
            </div>
          </template>

          <template v-if="column.dataIndex === 'research_action_name'">
            <p>
              {{
                record.project_researchs.filter(
                  (item) => item.research_action_name == "หัวหน้าโครงการวิจัย"
                )[0].research_name
              }}
            </p>
          </template>

          <template
            v-if="
              column.dataIndex === 'project_start_date' ||
              column.dataIndex === 'project_finish_date' ||
              column.dataIndex === 'updated_date' ||
              column.dataIndex === 'created_date' ||
              column.dataIndex === 'approve_date'
            "
          >
            <div>
              {{
                column.dataIndex === "project_start_date"
                  ? formatDate(record.project_start_date)
                  : column.dataIndex === "project_finish_date"
                  ? formatDate(record.project_finish_date)
                  : column.dataIndex === "updated_date"
                  ? formatDate(record.updated_date)
                  : column.dataIndex === "created_date"
                  ? formatDate(record.created_date)
                  : formatDate(record.approve_date)
              }}
            </div>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import BaseIconVue from "./Icon/BaseIcon.vue";
import DateFunction from "../services/DateFunction";

export default {
  components: {
    BaseIconVue,
  },

  props: {
    isHeader: {
      Type: Boolean,
      default: true,
    },
    // isFilterType: {
    //   Type: Boolean,
    //   default: true,
    // },
    columnsList: {
      Type: String,
    },
    tableData: {
      Type: String,
    },
    onClickedRows: {
      Type: Function,
    },
    dataLength: {
      Type: Number,
      default: 0,
    },
    paginationConfig: {
      Type: String,
    },
    filterTable: {
      Type: String,
    },
    sortBy: {
      Type: Number,
    },
    sortChanged: {
      Type: Function,
    },
    statusChanged: {
      Type: Function,
    },
    approveClicked: {
      Type: Function,
      required: false,
    },
    fileDownload: {
      Type: Function,
      required: false,
    },
    fileDeleted: {
      Type: Function,
      required: false,
    },
    tableStrip: {
      Type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.valueSort = this.sortBy;
  },

  computed: {
    numberedData() {
      return this.tableData.map((item, index) => {
        return {
          ...item,
          rowNumber: index + 1,
        };
      });
    },
  },

  methods: {
    approveStatus(status, index) {
      switch (status) {
        case "funding":
          return "สัญญารับทุน";
        case "progress":
          return `รายงานความคืบหน้า ${index}`;
        case "complete":
          return "รายงานฉบับสมบูรณ์";
        case "ohc":
          return "นำส่ง OHC";
        case "edit":
          return "รายงานฉบับแก้ไข";
      }
    },

    changeValues(value) {
      this.sortChanged(value);
      this.valueSort = value;
    },

    changeTab(tab) {
      this.statusChanged(tab);
      this.activeValue = tab;
    },

    customRow(record) {
      return {
        onClick: () => {
          if (this.onClickedRows) {
            this.onClickedRows(record);
          }
        },
      };
    },

    formatDate(date) {
      if (date) {
        return DateFunction.formatDate(date);
      } else {
        return "-";
      }
    },

    getRowClassName(record, index) {
      if (record.approve_status == "approve" && this.tableStrip) {
        return "table-approve";
      } else if (record.approve_status == "cancel" && this.tableStrip) {
        return "table-cancel";
      }
    },
  },

  data() {
    return {
      valueSort: 0,
      activeValue: "",
      editableData: reactive({}),
    };
  },
};
</script>

<style scoped>
.button-tap {
  border: 0;
  padding: 8px 16px;
  border-radius: 40px;
  height: 52px;
  color: #f39dbe;
  box-shadow: none !important;
}

.button-tap:hover {
  color: rgb(237 64 129 / 100%);
}

.button-tap:focus {
  outline: 0 !important;
}

.active {
  background-color: #f7d5e2;
  color: #ed4081;
}
</style>
