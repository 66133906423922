import { createStore } from "vuex";
import EncryptServices from "../services/EncryptServices";

const encryptedUser = localStorage.getItem("user");
const user = encryptedUser
  ? JSON.parse(EncryptServices.decrypt(encryptedUser))
  : null;

const store = createStore({
  state() {
    return {
      routeName: "",
      childName: "",
      sideBar: true,
      user: user,

      file_1: null,
      file_2: null,
      file_3: null,
    };
  },

  mutations: {
    setBread(state, value) {
      state.routeName = value;
    },

    setChild(state, value) {
      state.childName = value;
    },

    setToggleSideBar(state) {
      state.sideBar = !state.sideBar;
    },

    setUser(state, value) {
      state.user = value;
    },

    setFileSave(state, value) {
      switch (value.fileNo) {
        case 1:
          state.file_1 = {
            name: value.name,
            files: value.files,
          };
          break;
        case 2:
          state.file_2 = {
            name: value.name,
            files: value.files,
          };
          break;
        case 3:
          state.file_3 = {
            name: value.name,
            files: value.files,
          };
          break;
      }
    },

    setClearFileSave(state) {
      state.file_1 = null;
      state.file_2 = null;
      state.file_3 = null;
    },
  },
});

export default store;
