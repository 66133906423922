<template>
  <div>
    <!-- <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >  -->
    <div
      class="items-center flex justify-between bg-white px-6 py-2"
      :style="{ width: '200px', borderRadius: '40px' }"
    >
      <div>
        <p>{{ $store.state.user.username }}</p>
        <p :style="{ fontSize: '14px'}">{{ $store.state.user.role }}</p>
      </div>
      <a
        class="text-blueGray-500 block"
        href="#pablo"
        ref="btnDropdownRef"
        v-on:click="toggleDropdown($event)"
      >
        <div
          :style="{
            width: '32px',
            height: '32px',
            backgroundColor: '#F06599',
          }"
          class="rounded-full flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.97221 10.4714C8.84719 10.5963 8.67766 10.6665 8.50088 10.6665C8.3241 10.6665 8.15456 10.5963 8.02955 10.4714L4.25821 6.70003C4.19454 6.63853 4.14375 6.56496 4.10881 6.48363C4.07387 6.40229 4.05548 6.31481 4.05471 6.22629C4.05394 6.13777 4.07081 6.04999 4.10433 5.96806C4.13785 5.88613 4.18735 5.81169 4.24995 5.7491C4.31254 5.6865 4.38698 5.637 4.46891 5.60348C4.55084 5.56996 4.63863 5.55309 4.72715 5.55386C4.81567 5.55463 4.90315 5.57302 4.98448 5.60796C5.06582 5.6429 5.13938 5.69368 5.20088 5.75736L8.50088 9.05736L11.8009 5.75736C11.9266 5.63592 12.095 5.56872 12.2698 5.57024C12.4446 5.57176 12.6118 5.64187 12.7354 5.76548C12.859 5.88908 12.9291 6.05629 12.9307 6.23109C12.9322 6.40589 12.865 6.57429 12.7435 6.70003L8.97221 10.4714Z"
              fill="white"
            />
          </svg>
        </div>
      </a>
    </div>
    <!-- </a> -->
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left p-3 list-none text-left rounded shadow-lg min-w-48 flex flex-col items-center"
      :style="{ gap: '6px' }"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <p>{{ $store.state.user.username }}</p>
      <p :style="{ fontSize: '14px'}">{{ $store.state.user.role }}</p>
      <ButtonUC
        :label="'ออกจากระบบ'"
        :isBg="true"
        :onClicked="() => logout()"
      />
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

import image from "@/assets/img/team-1-800x800.jpg";
import ButtonUC from "../ButtonUC.vue";

import { HTTP } from "../../http-common";

export default {
  components: { ButtonUC },

  data() {
    return {
      dropdownPopoverShow: false,
      image: image,
    };
  },

  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },

    async logout() {
      const { data } = await HTTP.post("/logout");
      localStorage.removeItem("user");
      this.$router.push("/login");
    },
  },
};
</script>
