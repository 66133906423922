import moment from "moment";

const dateFunction = {
  formatDate: (date) => {
    return moment(date).add(543, "year").format("DD/MM/YYYY");
  },

  getSearchDate: (date) => {
    if (date == null) {
      return ""
    }
    return moment(new Date(date)).format("YYYY-MM-DD");
  },
};

export default dateFunction;
