<template>
  <div>
    <div class="flex gap-4">
      <div class="flex flex-col gap-1 w-full">
        <label>วันที่เริ่มต้นโครงการ</label>
        <a-date-picker placeholder="วันที่เริ่มต้นโครงการ" :style="{
          borderRadius: '40px',
          padding: !smallSize ? '9px 16px' : '4px 16px',
          border: '1px solid #B8B9BA',
        }" class="w-full" :value="startDate" @change="handleStartDateChange" :format="dateFormat"
          :disabled-date="disabledStartDate" :locale="locale" />
      </div>
      <div class="flex flex-col gap-1 w-full">
        <label>วันที่สิ้นสุดโครงการ</label>
        <a-date-picker placeholder="วันที่สิ้นสุดโครงการ" :style="{
          borderRadius: '40px',
          padding: !smallSize ? '9px 16px' : '4px 16px',
          border: '1px solid #B8B9BA',
        }" class="w-full" :value="endDate" @change="handleEndDateChange" :format="dateFormat"
          :disabled-date="disabledEndDate" :locale="locale" />
      </div>
    </div>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/th_TH";
import dayjs from "dayjs";
import th from "dayjs/locale/th";
import buddhistEra from "dayjs/plugin/buddhistEra";
import generatePicker from 'ant-design-vue/es/date-picker/generatePicker';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { noteOnce } from 'rc-util/lib/warning';


dayjs.locale({
  ...th,
  formats: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY เวลา H:mm',
    LLLL: 'วันddddที่ D MMMM YYYY เวลา H:mm',
  },
});

dayjs.extend(require('dayjs/plugin/buddhistEra'));

const parseLocale = () => 'th';

const parseNoMatchNotice = () => {
  noteOnce(false, 'Not match any format. Please help to fire an issue about this.');
};

const config = {
  ...dayjsGenerateConfig,
  getFixedDate: (string) => dayjs(string, ['DD-MM-BBBB', 'DD-MM-BBBB']),
  setYear: (date, year) => date.year(year - 543),
  getYear: (date) => Number(date.format('BBBB')),
  locale: {
    getWeekFirstDay: (locale) => dayjs().locale(parseLocale(locale)).localeData().firstDayOfWeek(),
    getWeekFirstDate: (locale, date) => date.locale(parseLocale(locale)).weekday(0),
    getWeek: (locale, date) => date.locale(parseLocale(locale)).week(),
    getShortWeekDays: (locale) => dayjs().locale(parseLocale(locale)).localeData().weekdaysMin(),
    getShortMonths: (locale) => dayjs().locale(parseLocale(locale)).localeData().monthsShort(),
    format: (locale, date, format) => {
      const convertFormat = format.replace('YYYY', 'BBBB');
      return date.locale(parseLocale(locale)).format(convertFormat);
    },
    parse: (locale, text, formats) => {
      const localeStr = parseLocale(locale);
      for (let i = 0; i < formats.length; i += 1) {
        const format = formats[i];
        const formatText = text;
        if (format.includes('wo') || format.includes('Wo')) {
          const year = formatText.split('-')[0];
          const weekStr = formatText.split('-')[1];
          const firstWeek = dayjs(year, 'BBBB').startOf('year').locale(localeStr);
          for (let j = 0; j <= 52; j += 1) {
            const nextWeek = firstWeek.add(j, 'week');
            if (nextWeek.format('Wo') === weekStr) {
              return nextWeek;
            }
          }
          parseNoMatchNotice();
          return null;
        }
        const date = dayjs(formatText, format, true).locale(localeStr);
        if (date.isValid()) {
          return date;
        }
      }

      if (text) {
        parseNoMatchNotice();
      }
      return null;
    },
  },
};

const DatePicker = generatePicker(config);

export default {
  // components: {
  //   ADatePicker: DatePicker,
  // },
  props: {
    format: {
      type: String,
    },
    onChanged: {
      type: Function,
    },
    startedDate: {
      Type: Date,
    },
    endedDate: {
      Type: Date,
    },
    smallSize: {
      Type: Boolean,
    },
  },

  watch: {
    startedDate(newValue) {
      this.startDate = newValue;
    },

    endedDate(newValue) {
      this.endDate = newValue;
    },
  },

  mounted() {

    dayjs.locale(th);
    dayjs.extend(buddhistEra);
    this.startDate = this.startedDate;
    this.endDate = this.endedDate;
  },

  methods: {
    disabledStartDate(currentDate) {
      return (
        currentDate &&
        this.endDate &&
        currentDate.valueOf() > this.endDate.valueOf()
      );
    },

    disabledEndDate(currentDate) {
      return (
        currentDate &&
        this.startDate &&
        currentDate.valueOf() <= this.startDate.valueOf()
      );
    },

    handleStartDateChange(date) {
      let newDate = date;

      if (dayjs(date).year() == dayjs().year()) {
        newDate = dayjs(date)
        // .add(543, "year");
      }

      this.startDate = newDate;
      this.onChanged({ startDate: this.startDate, endDate: this.endDate });
    },

    handleEndDateChange(date) {
      let newDate = date;

      if (dayjs(date).year() == dayjs().year()) {
        newDate = dayjs(date)
        // .add(543, "year");
      }

      this.endDate = newDate;
      this.onChanged({ startDate: this.startDate, endDate: this.endDate });
    },

    getDefaultPickerValue() {
      const currentYearBuddhistEra = dayjs().add(543, "year").year();
      return dayjs()
        .set("year", currentYearBuddhistEra)
        .startOf("year")
        .locale("th");
    },
  },

  data() {
    return {
      startDate: null,
      endDate: null,
      defaultPickerValue: this.getDefaultPickerValue(),
      locale: {
        lang: {
          locale: 'th',
          today: 'วันนี้',
          now: 'ตอนนี้',
          backToToday: 'กลับไปยังวันนี้',
          ok: 'ตกลง',
          timeSelect: 'เลือกเวลา',
          dateSelect: 'เลือกวัน',
          monthSelect: 'เลือกเดือน',
          yearSelect: 'เลือกปี',
          decadeSelect: 'เลือกทศวรรษ',
          yearFormat: 'BBBB',
          dateFormat: 'D/M/BBBB',
          dayFormat: 'D',
          dateTimeFormat: 'D/M/BBBB H:mm:ss',
          monthBeforeYear: true,
          previousMonth: 'เดือนก่อนหน้า (PageUp)',
          nextMonth: 'เดือนถัดไป (PageDown)',
          previousYear: 'ปีก่อนหน้า (Control + left)',
          nextYear: 'ปีถัดไป (Control + right)',
          previousDecade: 'ทศวรรษก่อนหน้า',
          nextDecade: 'ทศวรรษถัดไป',
          previousCentury: 'ศตวรรษก่อนหน้า',
          nextCentury: 'ศตวรรษถัดไป',
        },
        ...config.locale,
      },
      dateFormat: 'DD/MM/BBBB',
    };
  },
};
</script>
