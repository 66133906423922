<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M12.737 3.76689C10.137 1.16689 5.87031 1.16689 3.27031 3.76689C0.670313 6.36689 0.670313 10.6336 3.27031 13.2336C5.87031 15.8336 10.0703 15.8336 12.6703 13.2336C15.2703 10.6336 15.337 6.36689 12.737 3.76689ZM9.87031 11.3002L8.00364 9.43356L6.13698 11.3002L5.20365 10.3669L7.07031 8.50023L5.20365 6.63356L6.13698 5.70023L8.00364 7.56689L9.87031 5.70023L10.8036 6.63356L8.93698 8.50023L10.8036 10.3669L9.87031 11.3002Z"
      fill="white"
    />
  </svg>
</template>
