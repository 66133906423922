<template>
  <div class="flex flex-wrap">
    <div class="w-full flex flex-col gap-4">
      <SearchBoxVue
        :onChanged="(v) => (search_value = v)"
        :onClickedSearch="fetchResearchList"
        :researchNameOptions="project_name"
        :researchLeadOptions="research_name"
      />
      <TableUCVue
        :columnsList="columns"
        :tableData="dataValues"
        :onClickedRows="onClickRows"
        :paginationConfig="paginationConfig"
        :filterTable="filterTable"
        :sortBy="sort_by"
        :sortChanged="
          (v) => {
            sort_by = v;
            fetchResearchList();
          }
        "
      />
    </div>
    <!-- <router-view></router-view> -->
  </div>
</template>
<script>
import SearchBoxVue from "../../components/SearchBox/SearchBox.vue";
import TableUCVue from "../../components/TableUC.vue";
import { HTTP } from "../../http-common";
import DateFunction from "../../services/DateFunction";

export default {
  components: {
    SearchBoxVue,
    TableUCVue,
  },

  mounted() {
    this.fetchResearchList();
    this.$store.commit("setBread", this.$router.currentRoute._value);
  },

  methods: {
    async fetchResearchList() {
      let loading = this.$loading.show();

      let body = {
        limit: this.paginationConfig.pageSize,
        offset:
          this.paginationConfig.pageSize * (this.paginationConfig.current - 1),
        search_value: {
          ...this.search_value,
          project_start_date: DateFunction.getSearchDate(
            this.search_value.project_start_date
          ),
          project_end_date: DateFunction.getSearchDate(
            this.search_value.project_end_date
          ),
        },
        sort_column: "",
        sort_by: "",
      };

      switch (this.sort_by) {
        case 0:
          body = { ...body, sort_column: "updated_date", sort_by: "desc" };
          break;
        case 1:
          body = { ...body, sort_column: "updated_date", sort_by: "asc" };
          break;
        case 2:
          body = { ...body, sort_column: "created_date", sort_by: "desc" };
          break;
        case 3:
          body = { ...body, sort_column: "created_date", sort_by: "asc" };
          break;
      }

      const { data } = await HTTP.post("/getProjects", body);

      this.project_name = data.project_name;

      this.research_name = data.research_name;

      if (data.data && data.data.length > 0) {
        this.dataValues = data.data;
        this.paginationConfig.total = data.total;

        loading.hide();
      } else {
        loading.hide();
        this.dataValues = [];
        this.paginationConfig.total = 0;
      }
      loading.hide();
    },

    onClickRows(data) {
      this.$router.push(`/research-details/${data.project_id}`);
    },

    handlePageChange(page) {
      this.paginationConfig = {
        ...this.paginationConfig,
        current: page,
      };
      this.fetchResearchList();
    },

    handlePageSizeChange(current, pageSize) {
      this.paginationConfig = {
        ...this.paginationConfig,
        current: current,
        pageSize: pageSize,
      };
    },
  },

  data() {
    return {
      loader: "",
      search_value: null,
      sortOptions: 0,

      columns: [
        {
          title: "ชื่อโครงการวิจัย",
          dataIndex: "project_name_tha",
          width: 250,
        },
        {
          title: "ประเภทโครงการวิจัย",
          dataIndex: "research_type",
          width: 150,
        },
        {
          title: "หัวหน้าโครงการ",
          dataIndex: "research_action_name",
          width: 280,
        },
        {
          title: "วันที่เริ่มต้นโครงการ",
          dataIndex: "project_start_date",
          align: "center",
          width: 100,
        },
        {
          title: "วันที่สิ้นสุดโครงการ",
          dataIndex: "project_finish_date",
          align: "center",
          width: 100,
        },
        {
          title: "ขั้นตอนการดำเนินงาน",
          dataIndex: "approve_type",
          width: 180,
        },
        {
          title: "ผลการดำเนินงาน",
          dataIndex: "approve_status",
          align: "center",
          width: 150,
        },
        {
          title: "วันที่ดำเนินการ",
          dataIndex: "approve_date",
          align: "center",
          width: 150,
        },
      ],

      sort_by: 0,
      filterTable: [
        { label: "เรียงตามโครงการวิจัยที่อัปเดตใหม่ที่สุด", value: 0 },
        { label: "เรียงตามโครงการวิจัยที่อัปเดตเก่าที่สุด", value: 1 },
        { label: "เรียงตามโครงการวิจัยที่สร้างใหม่ที่สุด", value: 2 },
        { label: "เรียงตามโครงการวิจัยที่สร้างเก่าที่สุด", value: 3 },
      ],

      paginationConfig: {
        total: 0,
        pageSize: 10,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: [5, 10, 50, 100],
        onChange: this.handlePageChange,
        onShowSizeChange: this.handlePageSizeChange,
      },

      dataValues: [],
      project_name: [],
      research_name: [],
      dataLength: 0,
    };
  },
};
</script>
