<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.5 13.3359C20.2348 13.3359 19.9804 13.4413 19.7929 13.6288C19.6054 13.8164 19.5 14.0707 19.5 14.3359V19.3359H5.5V14.3359C5.5 14.0707 5.39464 13.8164 5.20711 13.6288C5.01957 13.4413 4.76522 13.3359 4.5 13.3359C4.23478 13.3359 3.98043 13.4413 3.79289 13.6288C3.60536 13.8164 3.5 14.0707 3.5 14.3359V19.3359C3.5 19.8664 3.71071 20.3751 4.08579 20.7502C4.46086 21.1252 4.96957 21.3359 5.5 21.3359H19.5C20.0304 21.3359 20.5391 21.1252 20.9142 20.7502C21.2893 20.3751 21.5 19.8664 21.5 19.3359V14.3359C21.5 14.0707 21.3946 13.8164 21.2071 13.6288C21.0196 13.4413 20.7652 13.3359 20.5 13.3359Z"
      fill="#0066FF"
    />
    <path
      d="M13.2123 3.62194C13.0264 3.43902 12.7761 3.33633 12.5153 3.33594H12.5093C12.2451 3.33646 11.9919 3.44184 11.8053 3.62894L7.80529 7.62894C7.70978 7.72118 7.6336 7.83153 7.58119 7.95353C7.52878 8.07554 7.50119 8.20676 7.50004 8.33954C7.49888 8.47232 7.52419 8.60399 7.57447 8.72689C7.62475 8.84979 7.699 8.96144 7.79289 9.05533C7.88679 9.14922 7.99844 9.22348 8.12133 9.27376C8.24423 9.32404 8.37591 9.34934 8.50869 9.34819C8.64147 9.34703 8.77269 9.31945 8.89469 9.26704C9.0167 9.21463 9.12704 9.13845 9.21929 9.04294L11.5123 6.74994V16.3359C11.5123 16.6012 11.6176 16.8555 11.8052 17.043C11.9927 17.2306 12.2471 17.3359 12.5123 17.3359C12.7775 17.3359 13.0319 17.2306 13.2194 17.043C13.4069 16.8555 13.5123 16.6012 13.5123 16.3359V6.74994L15.8053 9.04294C15.9939 9.2251 16.2465 9.32589 16.5087 9.32361C16.7709 9.32133 17.0217 9.21616 17.2071 9.03076C17.3925 8.84535 17.4977 8.59453 17.5 8.33234C17.5022 8.07014 17.4014 7.81754 17.2193 7.62894L13.2123 3.62194Z"
      fill="#0066FF"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      Type: String,
    },
  },
};
</script>
