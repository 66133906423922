<template>
  <!-- Navbar -->
  <div
    class="fixed top-0 left-0 z-10 flex-row flex-nowrap justify-start items-center py-2 w-full"
    :class="{
      'md:ml-64 md:calWidth': $store.state.sideBar,
      'transition-all duration-300 ease-in-out': true,
    }"
    :style="{ backgroundColor: '#F06599' }"
  >
    <div
      class="w-full mx-auto items-center block justify-between md:flex-nowrap flex-wrap px-6"
    >
      <ul class="flex-row list-none items-center flex justify-between">
        <button class="bg-white p-1 rounded-full" @click="sideBarToggle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M21.5 4.5H3.5V7.5H21.5V4.5ZM21.5 10.5H9.5V13.5H21.5V10.5ZM21.5 16.5H3.5V19.5H21.5V16.5ZM8 8.25L3.5 12L8 15V8.25Z"
              fill="#F06599"
            />
          </svg>
        </button>
        <user-dropdown />
      </ul>
    </div>
  </div>
  <!-- End Navbar -->
</template>

<script>
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";

export default {
  components: {
    UserDropdown,
  },

  methods: {
    sideBarToggle() {
      this.$store.commit("setToggleSideBar");
    },
  },
};
</script>

<style scoped>
.calWidth {
  width: calc(100% - 16rem);
}
</style>
