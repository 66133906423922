<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M20.5 5C20.7652 5 21.0196 5.10536 21.2071 5.29289C21.3946 5.48043 21.5 5.73478 21.5 6C21.5 6.26522 21.3946 6.51957 21.2071 6.70711C21.0196 6.89464 20.7652 7 20.5 7H19.5L19.497 7.071L18.564 20.142C18.5281 20.6466 18.3023 21.1188 17.9321 21.4636C17.5619 21.8083 17.0749 22 16.569 22H8.43C7.92414 22 7.43707 21.8083 7.06688 21.4636C6.6967 21.1188 6.47092 20.6466 6.435 20.142L5.502 7.072C5.50048 7.04803 5.49982 7.02402 5.5 7H4.5C4.23478 7 3.98043 6.89464 3.79289 6.70711C3.60536 6.51957 3.5 6.26522 3.5 6C3.5 5.73478 3.60536 5.48043 3.79289 5.29289C3.98043 5.10536 4.23478 5 4.5 5H20.5ZM14.5 2C14.7652 2 15.0196 2.10536 15.2071 2.29289C15.3946 2.48043 15.5 2.73478 15.5 3C15.5 3.26522 15.3946 3.51957 15.2071 3.70711C15.0196 3.89464 14.7652 4 14.5 4H10.5C10.2348 4 9.98043 3.89464 9.79289 3.70711C9.60536 3.51957 9.5 3.26522 9.5 3C9.5 2.73478 9.60536 2.48043 9.79289 2.29289C9.98043 2.10536 10.2348 2 10.5 2H14.5Z"
      fill="#ED4040"
    />
  </svg>
</template>
