<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.7646 3.69309L14.0612 5.39647L18.6364 9.97168L20.3398 8.2683C21.2196 7.38845 21.2196 5.9631 20.3398 5.08325L18.9531 3.69309C18.0733 2.81324 16.6479 2.81324 15.7681 3.69309H15.7646ZM13.2658 6.19186L5.06212 14.3991C4.6961 14.7651 4.42863 15.2191 4.28081 15.7153L3.03495 19.9492C2.94697 20.2483 3.02791 20.5686 3.24611 20.7868C3.46432 21.005 3.78458 21.0859 4.08021 21.0014L8.31403 19.7556C8.81027 19.6078 9.26427 19.3403 9.63029 18.9743L17.841 10.7671L13.2658 6.19186Z"
      fill="#0066FF"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      Type: String,
    },
  },
};
</script>
