<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M12.5 1.5C15.2848 1.5 17.9555 2.60625 19.9246 4.57538C21.8938 6.54451 23 9.21523 23 12C23 14.7848 21.8938 17.4555 19.9246 19.4246C17.9555 21.3938 15.2848 22.5 12.5 22.5C9.71523 22.5 7.04451 21.3938 5.07538 19.4246C3.10625 17.4555 2 14.7848 2 12C2 9.21523 3.10625 6.54451 5.07538 4.57538C7.04451 2.60625 9.71523 1.5 12.5 1.5ZM11.192 14.0715L8.8595 11.7375C8.77588 11.6539 8.67661 11.5876 8.56736 11.5423C8.4581 11.497 8.34101 11.4737 8.22275 11.4737C8.10449 11.4737 7.9874 11.497 7.87814 11.5423C7.76889 11.5876 7.66962 11.6539 7.586 11.7375C7.41712 11.9064 7.32225 12.1354 7.32225 12.3743C7.32225 12.6131 7.41712 12.8421 7.586 13.011L10.556 15.981C10.6394 16.065 10.7386 16.1317 10.8479 16.1773C10.9571 16.2228 11.0744 16.2462 11.1927 16.2462C11.3111 16.2462 11.4284 16.2228 11.5376 16.1773C11.6469 16.1317 11.7461 16.065 11.8295 15.981L17.9795 9.8295C18.0642 9.74623 18.1316 9.647 18.1778 9.53755C18.224 9.42809 18.2481 9.31057 18.2487 9.19177C18.2492 9.07297 18.2262 8.95523 18.1811 8.84535C18.1359 8.73547 18.0694 8.63562 17.9854 8.55156C17.9015 8.46751 17.8017 8.4009 17.6919 8.3556C17.5821 8.31029 17.4644 8.28718 17.3455 8.28759C17.2267 8.288 17.1092 8.31193 16.9997 8.358C16.8902 8.40407 16.7909 8.47136 16.7075 8.556L11.192 14.0715Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      Type: String,
    }
  }
};
</script>

<style lang="scss" scoped></style>
