<template>
  <div class="flex flex-col gap-1">
    <div class="flex">
      <label v-if="header" :style="{ visibility: 'hidden' }">{{
        header
      }}</label>
    </div>
    <button
      :style="{
        borderRadius: '40px',
        backgroundColor: isDisabled ? '#F3F4F6' : isBg ? '#F06599' : '#FFF',
        border: isDisabled ? '2px solid #D6D6D8' : '2px solid #F06599',
        color: isDisabled ? '#D6D6D8' : isBg ? '#FFF' : '#F06599',
        padding: '6px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
        width: '100%',
      }"
      @click="onClicked"
      :disabled="isDisabled"
    >
      <BaseIconVue :name="iconName" :color="iconColor" />
      {{ label }}
    </button>
  </div>
</template>

<script>
import BaseIconVue from "./Icon/BaseIcon.vue";

export default {
  components: {
    BaseIconVue,
  },
  
  props: {
    label: {
      Type: String,
    },
    isBg: {
      Type: Boolean,
      default: false,
    },
    onClicked: {
      Type: Function,
    },
    iconName: {
      Type: String,
    },
    iconColor: {
      Type: String,
    },
    isDisabled: {
      Type: Boolean,
      default: false,
    },
    header: {
      Type: String,
    },
  },
  data() {
    return {};
  },
};
</script>
