<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.46154 11.1923C5.46154 8.30343 7.80343 5.96154 10.6923 5.96154C13.5812 5.96154 15.9231 8.30343 15.9231 11.1923C15.9231 14.0812 13.5812 16.4231 10.6923 16.4231C7.80343 16.4231 5.46154 14.0812 5.46154 11.1923ZM10.6923 4C6.72011 4 3.5 7.22011 3.5 11.1923C3.5 15.1645 6.72011 18.3846 10.6923 18.3846C12.3248 18.3846 13.8302 17.8407 15.0373 16.9243L18.8257 20.7127C19.2087 21.0958 19.8297 21.0958 20.2127 20.7127C20.5958 20.3297 20.5958 19.7087 20.2127 19.3257L16.4243 15.5373C17.3407 14.3302 17.8846 12.8248 17.8846 11.1923C17.8846 7.22011 14.6645 4 10.6923 4Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
    },
  },
};
</script>
