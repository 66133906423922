<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.88866 3C7.61336 3.63079 7.47172 4.31176 7.47266 5C7.47266 5.53044 7.68337 6.03914 8.05844 6.41422C8.43352 6.78929 8.94222 7 9.47266 7H15.4727C16.0031 7 16.5118 6.78929 16.8869 6.41422C17.2619 6.03914 17.4727 5.53044 17.4727 5C17.4727 4.289 17.3247 3.612 17.0567 3H18.4727C19.0031 3 19.5118 3.21072 19.8869 3.58579C20.2619 3.96086 20.4727 4.46957 20.4727 5V20C20.4727 20.5304 20.2619 21.0391 19.8869 21.4142C19.5118 21.7893 19.0031 22 18.4727 22H6.47266C5.94222 22 5.43352 21.7893 5.05844 21.4142C4.68337 21.0391 4.47266 20.5304 4.47266 20V5C4.47266 4.46957 4.68337 3.96086 5.05844 3.58579C5.43352 3.21072 5.94222 3 6.47266 3H7.88866ZM12.4727 14H9.47266C9.20744 14 8.95309 14.1054 8.76555 14.2929C8.57801 14.4804 8.47266 14.7348 8.47266 15C8.47266 15.2652 8.57801 15.5196 8.76555 15.7071C8.95309 15.8946 9.20744 16 9.47266 16H12.4727C12.7379 16 12.9922 15.8946 13.1798 15.7071C13.3673 15.5196 13.4727 15.2652 13.4727 15C13.4727 14.7348 13.3673 14.4804 13.1798 14.2929C12.9922 14.1054 12.7379 14 12.4727 14ZM15.4727 10H9.47266C9.21778 10.0003 8.97262 10.0979 8.78729 10.2729C8.60195 10.4478 8.49042 10.687 8.47548 10.9414C8.46055 11.1958 8.54333 11.4464 8.70692 11.6418C8.87051 11.8373 9.10256 11.9629 9.35566 11.993L9.47266 12H15.4727C15.7379 12 15.9922 11.8946 16.1798 11.7071C16.3673 11.5196 16.4727 11.2652 16.4727 11C16.4727 10.7348 16.3673 10.4804 16.1798 10.2929C15.9922 10.1054 15.7379 10 15.4727 10ZM12.4727 2C12.8948 1.99938 13.3124 2.08817 13.6978 2.26053C14.0831 2.43289 14.4277 2.68491 14.7087 3C15.1367 3.478 15.4127 4.093 15.4637 4.772L15.4727 5H9.47266C9.47266 4.275 9.72966 3.61 10.1577 3.092L10.2367 3C10.7867 2.386 11.5847 2 12.4727 2Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "",
    },
  },
};
</script>
