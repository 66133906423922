// layouts

import Admin from "@/layouts/Admin.vue";
// import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import ResearchList from "@/views/research/ResearchList.vue";
import ResearchCheck from "@/views/research/ResearchCheck.vue";
// import Tables from "@/views/admin/Tables.vue";
import ResearchDetail from "@/views/research/ResearchDetail.vue";

// views for Auth layout

// import Login from "@/views/auth/Login.vue";
// import Register from "@/views/auth/Register.vue";

// views without layouts

// import Landing from "@/views/Landing.vue";
// import Profile from "@/views/Profile.vue";
import Index from "@/views/Index.vue";

// routes

import EncryptServices from "../services/EncryptServices";

const commonGuard = (to, from, next) => {
  const encryptedUser = localStorage.getItem("user");
  const user = encryptedUser
    ? JSON.parse(EncryptServices.decrypt(encryptedUser))
    : null;
  if (user) {
    if (user.role == "research" && to.path == "/research-check") {
      next("/research-list");
    }
    next();
  } else {
    next("/login");
  }
};

const routes = [
  {
    path: "/login",
    component: Index,
    children: [
      {
        path: "/login",
        component: Index,
      },
    ],
  },
  {
    path: "/",
    redirect: "/research-list",
    component: Admin,
    children: [
      {
        path: "/research-list",
        name: "รายการโครงการวิจัย",
        component: ResearchList,
        beforeEnter: commonGuard,
      },
      {
        path: "/research-check",
        name: "ตรวจสอบโครงการวิจัย",
        component: ResearchCheck,
        beforeEnter: commonGuard,
      },
      {
        path: "/research-details/:project_id",
        name: "รายละเอียดโครงการวิจัย",
        component: ResearchDetail,
        beforeEnter: (to, from, next) => {
          if (from.path == "/research-list" || from.path == "/research-check") {
            return next();
          }
          next("/research-list");
        },
      },
    ],
  },
];

export default routes;
