<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M21.0537 3.21843C20.9525 3.11768 20.8246 3.04793 20.6851 3.01737C20.5456 2.98681 20.4003 2.99672 20.2662 3.04593L3.76624 9.04593C3.62394 9.0999 3.50143 9.19589 3.41498 9.32114C3.32853 9.44639 3.28223 9.59498 3.28223 9.74718C3.28223 9.89937 3.32853 10.048 3.41498 10.1732C3.50143 10.2985 3.62394 10.3945 3.76624 10.4484L10.2087 13.0209L14.9637 8.25093L16.0212 9.30843L11.2437 14.0859L13.8237 20.5284C13.8793 20.668 13.9755 20.7876 14.0999 20.8719C14.2242 20.9561 14.371 21.0011 14.5212 21.0009C14.6728 20.9978 14.8199 20.9489 14.943 20.8605C15.0662 20.7721 15.1597 20.6485 15.2112 20.5059L21.2112 4.00593C21.2623 3.87323 21.2747 3.72875 21.2468 3.58932C21.2189 3.44989 21.1519 3.32126 21.0537 3.21843Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      Type: String,
    },
  },
};
</script>
