<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 13.02C19.7348 13.02 19.4804 13.1254 19.2929 13.3129C19.1054 13.5004 19 13.7548 19 14.02V19.02H5V14.02C5 13.7548 4.89464 13.5004 4.70711 13.3129C4.51957 13.1254 4.26522 13.02 4 13.02C3.73478 13.02 3.48043 13.1254 3.29289 13.3129C3.10536 13.5004 3 13.7548 3 14.02V19.02C3 19.5505 3.21071 20.0592 3.58579 20.4342C3.96086 20.8093 4.46957 21.02 5 21.02H19C19.5304 21.02 20.0391 20.8093 20.4142 20.4342C20.7893 20.0592 21 19.5505 21 19.02V14.02C21 13.7548 20.8946 13.5004 20.7071 13.3129C20.5196 13.1254 20.2652 13.02 20 13.02Z"
      fill="#0066FF"
    />
    <path
      d="M12.7123 16.734C12.5264 16.9169 12.2761 17.0196 12.0153 17.02H12.0093C11.7451 17.0195 11.4919 16.9141 11.3053 16.727L7.30529 12.727C7.20978 12.6348 7.1336 12.5244 7.08119 12.4024C7.02878 12.2804 7.00119 12.1492 7.00004 12.0164C6.99888 11.8836 7.02419 11.752 7.07447 11.6291C7.12475 11.5062 7.199 11.3945 7.29289 11.3006C7.38679 11.2067 7.49844 11.1325 7.62133 11.0822C7.74423 11.0319 7.87591 11.0066 8.00869 11.0078C8.14147 11.0089 8.27269 11.0365 8.39469 11.0889C8.5167 11.1413 8.62704 11.2175 8.71929 11.313L11.0123 13.606V4.02002C11.0123 3.7548 11.1176 3.50045 11.3052 3.31291C11.4927 3.12538 11.7471 3.02002 12.0123 3.02002C12.2775 3.02002 12.5319 3.12538 12.7194 3.31291C12.9069 3.50045 13.0123 3.7548 13.0123 4.02002V13.606L15.3053 11.313C15.4939 11.1309 15.7465 11.0301 16.0087 11.0323C16.2709 11.0346 16.5217 11.1398 16.7071 11.3252C16.8925 11.5106 16.9977 11.7614 17 12.0236C17.0022 12.2858 16.9014 12.5384 16.7193 12.727L12.7123 16.734Z"
      fill="#0066FF"
    />
  </svg>
</template>
