<template>
  <div>
    <div class="flex flex-col gap-1">
      <div class="flex">
        <label :for="name" v-if="label">{{ label }}</label>
      </div>
      <a-date-picker
        :placeholder="placeholder"
        :style="{
          borderRadius: '40px',
          padding: !smallSize ? '9px 16px' : '4px 16px',
          border: '1px solid #B8B9BA',
        }"
        class="w-full"
        :value="dateValue"
        @change="
          (value) => {
            dateValue = value;
            onChangeDate(value);
          }
        "
        :format="dateFormat"
        :disabled-date="disabledDate"
        :default-picker-value="defaultPickerValue"
        :locale="locale"
      />
    </div>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/th_TH";
import dayjs from "dayjs";
import th from "dayjs/locale/th";
import buddhistEra from "dayjs/plugin/buddhistEra";

dayjs.locale(th);
dayjs.extend(buddhistEra);


export default {
  props: {
    dueDateValue: {
      Type: Date,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    dateInput: {
      type: Date,
    },
    minDate: {
      Type: Date,
    },
    maxDate: {
      Type: Date,
    },
    defaultDate: {
      Type: Date,
    },
    format: {
      Type: String,
    },
    smallSize: {
      Type: Boolean,
      default: false,
    },
    onChangeDate: {
      Type: Function,
      default: () => {},
    },
  },

  watch: {
    dueDateValue(newValue) {
      if (newValue) {
        this.dateValue = newValue;
      } else {
        this.dateValue = "";
      }
    },
  },

  mounted() {
    this.dateValue = this.dueDateValue;
  },

  methods: {
    disabledDate(current) {
      const yesterday = dayjs(new Date()).subtract(1, "day");
      return current && current.valueOf() <= yesterday.valueOf();
    },

    getDefaultPickerValue() {
      const currentYearBuddhistEra = dayjs().year();
      return dayjs().set("year", currentYearBuddhistEra);
    },
  },

  data() {
    return {
      dateValue: null,
      defaultPickerValue: this.getDefaultPickerValue(),
      locale: {
        lang: {
          ...locale.lang,
          yearFormat: "BBBB",
          dateFormat: "DD/MM/BBBB",
          dateTimeFormat: "DD/MM/BBBB HH:mm:ss",
        },
        weekFormat: "BBBB-wo",
        monthFormat: "BBBB-MM",
      },
      dateFormat: "DD/MM/BBBB",
    };
  },
};
</script>

<style lang="scss" scoped></style>
