<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M19.6016 4.89961C15.7016 0.999609 9.30156 0.999609 5.40156 4.89961C1.50156 8.79961 1.50156 15.1996 5.40156 19.0996C9.30156 22.9996 15.6016 22.9996 19.5016 19.0996C23.4016 15.1996 23.5016 8.79961 19.6016 4.89961ZM15.3016 16.1996L12.5016 13.3996L9.70156 16.1996L8.30156 14.7996L11.1016 11.9996L8.30156 9.19961L9.70156 7.79961L12.5016 10.5996L15.3016 7.79961L16.7016 9.19961L13.9016 11.9996L16.7016 14.7996L15.3016 16.1996Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      Type: String,
    }
  }
};
</script>
